import { PageData } from "@mfe/tef-tracking-types";
import { PageDataDL } from "#/dataLayer";

export const mapPageData = (pageData: PageData): PageDataDL => {
    return {
        customer_channel: pageData.customerChannel, // example values: 'mobile app', 'web view', 'retail app'
        path: pageData.pagePath, // pagePath
        category: pageData.pageCategory, // values: 'pdp', 'cart', 'checkout', 'service' , 'list', 'confirmation', 'info'
        application: pageData.application, // e.g. pixie, cms, e-buy, e-shop + SPA information
        brand: pageData.brandName, // e.g. o2 | blau | whatsappsim | alditalk | nettokom etc...
        flow_name: pageData.flowName, // The flow name with underscores. For reference see the section 'Standard Values'
        // funnel_step: pageData.pageFunnelStep,
    };
};
