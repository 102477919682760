import { ViewItemEvent } from "@mfe/tef-tracking-types";
import { mapViewItem } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { dataLayerPush } from "#/dataLayer";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleViewItem: TrackingEventHandler<ViewItemEvent> = async (evt) => {
    const offerId = evt.detail.trackingEvent.offer.id;
    const backendItems = await fetchCommerceData([{ id: offerId }], evt.detail.trackingEvent.flowName);
    return dataLayerPush(
        mapViewItem(evt.detail.trackingEvent, backendItems[offerId]),
        evt.detail.trackingEvent.mfeName,
    );
};
