import { PurchaseEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { fetchCommerceData } from "#/trackingClient";
import { mapPurchase } from "#/payloadMapper";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handlePurchase: TrackingEventHandler<PurchaseEvent> = async (evt) => {
    return dataLayerPush(
        mapPurchase(
            evt.detail.trackingEvent,
            await fetchCommerceData(evt.detail.trackingEvent.offers, evt.detail.trackingEvent.flowName),
        ),
        evt.detail.trackingEvent.mfeName,
    );
};
