import { ViewPromotionEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { fetchCommerceData } from "#/trackingClient";
import { mapViewPromotion } from "#/payloadMapper";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleViewPromotion: TrackingEventHandler<ViewPromotionEvent> = async (evt) => {
    return dataLayerPush(
        mapViewPromotion(
            evt.detail.trackingEvent,
            evt.detail.trackingEvent.offers
                ? await fetchCommerceData(evt.detail.trackingEvent.offers, evt.detail.trackingEvent.flowName)
                : undefined,
        ),
        evt.detail.trackingEvent.mfeName,
    );
};
