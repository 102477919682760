import { TopUpEvent } from "@mfe/tef-tracking-types";
import { TopUpDL } from "#/dataLayer";

export const mapTopUp = (evt: TopUpEvent): TopUpDL => {
    if ("thresholdInfo" in evt && "dateInfo" in evt) {
        return {
            event: "generic_set_top_complementary_click",
            event_name: "top_up",
            generic_set_top_complementary_click: {
                action: "click - select payment method",
                category: "service - set - automatic top-up",
                label: evt.label,
                threshold_value: evt.thresholdInfo?.value,
                threshold_amount: evt.thresholdInfo?.amount,
                threshold_amount_in_cents: evt.thresholdInfo?.amountInCents,
                date_amount: evt.dateInfo?.amount,
                date_amount_in_cents: evt.dateInfo?.amountInCents,
                message: "-",
                non_interaction: false,
                value: 0,
            },
        } as TopUpDL;
    }

    if ("thresholdInfo" in evt) {
        return {
            event: "generic_set_top_up_threshold_click",
            event_name: "top_up",
            generic_set_top_up_threshold_click: {
                action: "click - select payment method",
                category: "service - set - automatic top-up",
                label: evt.label,
                threshold_value: evt.thresholdInfo.value,
                threshold_amount: evt.thresholdInfo.amount,
                threshold_amount_in_cents: evt.thresholdInfo.amountInCents,
                message: "-",
                non_interaction: false,
                value: 0,
            },
        } as TopUpDL;
    }

    if ("dateInfo" in evt) {
        return {
            event: "generic_set_top_up_date_click",
            event_name: "top_up",
            generic_set_top_up_date_click: {
                action: "click - select payment method",
                category: "service - set - automatic top-up",
                label: evt.label,
                date_amount: evt.dateInfo.amount,
                date_amount_in_cents: evt.dateInfo.amountInCents,
                message: "-",
                non_interaction: false,
                value: 0,
            },
        } as TopUpDL;
    }

    throw new Error("Neither ThresholdInfo nor DateInfo is available when mapping TopUp");
};
