import type { TrackingEventName, TrackingNameEventMap } from "@mfe/tef-tracking-types";
import { TrackingEventHandler } from "./handler";

import {
    handleAddPaymentInfo,
    handleAddShippingInfo,
    handleAddToCart,
    handleBeginCheckout,
    handleExitFlow,
    handleFaqClick,
    handleFileDownload,
    handleFormExpand,
    handleGenericFlow,
    handleHintClick,
    handleHintDisplay,
    handleInformationDisplay,
    handleLogin,
    handleNotificationClick,
    handleNotificationDisplay,
    handleOtherInformation,
    handlePurchase,
    handleRedeemVoucher,
    handleRemoveFromCart,
    handleSelectItem,
    handleSelectPromotion,
    handleViewCart,
    handleViewItem,
    handleViewItemList,
    handleViewPromotion,
    handleVirtualPageView,
    handleTopUp,
} from "./handler";

import { BUS_PREFIX, TrackingBusEventPayload } from "#/bus";

type TrackingEventMap = {
    [K in TrackingEventName as `${typeof BUS_PREFIX}${K}`]: CustomEvent<
        TrackingBusEventPayload<TrackingNameEventMap[K]>
    >;
};

declare global {
    // eslint-disable-next-line prettier/prettier
    interface WindowEventMap extends TrackingEventMap {}
}

// FIXME: try and fix strange union issues with keyof
// const trackingEventHandlerMap: Record<keyof TrackingEventMap, TrackingEventHandler<keyof TrackingEvent>> = {
const trackingEventHandlerMap: Record<keyof TrackingEventMap, TrackingEventHandler<any>> = {
    // with ecommerce items
    "tef:tracking:view-promotion": handleViewPromotion,
    "tef:tracking:select-promotion": handleSelectPromotion,
    "tef:tracking:view-item-list": handleViewItemList,
    "tef:tracking:select-item": handleSelectItem,
    "tef:tracking:view-item": handleViewItem,
    "tef:tracking:add-to-cart": handleAddToCart,
    "tef:tracking:remove-from-cart": handleRemoveFromCart,
    "tef:tracking:view-cart": handleViewCart,
    "tef:tracking:begin-checkout": handleBeginCheckout,
    "tef:tracking:add-payment-info": handleAddPaymentInfo,
    "tef:tracking:add-shipping-info": handleAddShippingInfo,
    "tef:tracking:purchase": handlePurchase,
    "tef:tracking:hint-display": handleHintDisplay,
    "tef:tracking:hint-click": handleHintClick,
    "tef:tracking:form-expand": handleFormExpand,
    "tef:tracking:file-download": handleFileDownload,
    "tef:tracking:notification-display": handleNotificationDisplay,
    "tef:tracking:notification-click": handleNotificationClick,
    "tef:tracking:exit-flow": handleExitFlow,
    "tef:tracking:redeem-voucher": handleRedeemVoucher,
    "tef:tracking:faq-click": handleFaqClick,
    "tef:tracking:login": handleLogin,
    "tef:tracking:virtual-page-view": handleVirtualPageView,
    "tef:tracking:information-display": handleInformationDisplay,
    "tef:tracking:other-information": handleOtherInformation,
    "tef:tracking:generic-flow": handleGenericFlow,
    "tef:tracking:top-up": handleTopUp,
} as const;

export const registerTrackingEventListeners = () => {
    for (const key in trackingEventHandlerMap) {
        // @ts-ignore
        const handlerFunction = trackingEventHandlerMap[key];
        const eventName = key as keyof TrackingEventMap;

        attachHandler(eventName, handlerFunction);
    }
};

const attachHandler = (eventName: keyof TrackingEventMap, handler: TrackingEventHandler<any>) => {
    window.addEventListener(
        eventName,
        async (evt: CustomEvent<TrackingBusEventPayload<any>>) => {
            const timer = window.setTimeout(() => {
                evt.detail.promiseHandle.resolve(false);
                console.warn(`tracking timeout after ${evt.detail.timeoutMS}`);
            }, evt.detail.timeoutMS);
            try {
                // wait for ga to resolve
                await handler(evt);
                evt.detail.promiseHandle.resolve(true);
            } catch {
                evt.detail.promiseHandle.resolve(false);
            } finally {
                window.clearTimeout(timer);
                // always resolve to avoid need for try/catch in MFE
            }
        },
        { capture: true },
    );
};

export const availableEventKeys = Object.keys(trackingEventHandlerMap);
