import { BeginCheckoutEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { mapBeginCheckout } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleBeginCheckout: TrackingEventHandler<BeginCheckoutEvent> = async (evt) => {
    return dataLayerPush(
        mapBeginCheckout(
            evt.detail.trackingEvent,
            await fetchCommerceData(evt.detail.trackingEvent.offers, evt.detail.trackingEvent.flowName),
        ),
        evt.detail.trackingEvent.mfeName,
    );
};
