import { SelectItemEvent } from "@mfe/tef-tracking-types";

import { fetchCommerceData } from "#/trackingClient";
import { dataLayerPush } from "#/dataLayer";
import { mapSelectItem } from "#/payloadMapper";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleSelectItem: TrackingEventHandler<SelectItemEvent> = async (evt) => {
    return dataLayerPush(
        mapSelectItem(
            evt.detail.trackingEvent,
            (await fetchCommerceData([evt.detail.trackingEvent.offer], evt.detail.trackingEvent.flowName))[
                evt.detail.trackingEvent.offer.id
            ],
        ),
        evt.detail.trackingEvent.mfeName,
    );
};
