import { Flow } from "@mfe/tef-tracking-types";
import { TrackingItems } from "@tef/openapi-client";

const flowsThatExcludeOneTimePrice: Array<Flow> = ["change_base_offer", "sim_registration"];

const mapFlowSpecificItems = (items: TrackingItems, currentFlow?: Flow) => {
    const overrideOneTimeCosts = currentFlow && flowsThatExcludeOneTimePrice.includes(currentFlow);
    let flowSpecificItems: TrackingItems = {};

    for (let itemsKey in items) {
        flowSpecificItems[itemsKey] = {
            ...items[itemsKey],
            price_without_one_time_costs: undefined,
            // fallback to price because price_without_one_time_costs might not be available when the customer already has it in session storage
            price: overrideOneTimeCosts
                ? items[itemsKey].price_without_one_time_costs ?? items[itemsKey].price
                : items[itemsKey].price,
            one_time_costs: overrideOneTimeCosts ? "0" : items[itemsKey].one_time_costs,
        };
    }

    return flowSpecificItems;
};

export const trackingItemMapper = {
    mapFlowSpecificItems,
};
