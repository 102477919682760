// @ts-ignore
import { TrackingClient, TrackingItems } from "@tef/openapi-client";
import { cache } from "./cache";
import { Flow } from "@mfe/tef-tracking-types";
import { trackingItemMapper } from "./trackingItemMapper";

const trackingRestClient = new TrackingClient({
    BASE: `${import.meta.env.VITE_BFF_BASE_PATH ?? "http://localhost:9001"}/tracking`,
});

export type FetchCommerceData = (items: Array<{ id: string }>, flow?: Flow) => Promise<TrackingItems>;
const cachingEnabled = true;

/**
 * Wrapper around openapi client to enable easy mocking without server
 *
 * @param items list of offerids to fetch tracking data from bff
 * @param flow of the current event
 * @returns TrackingItems (@tef/openapi-client)
 */
export const fetchCommerceData: FetchCommerceData = async (
    items: Array<{ id: string }>,
    flow?: Flow,
): Promise<TrackingItems> => {
    const ids = [...new Set(items.map((item) => item.id))];

    async function getItems(): Promise<TrackingItems> {
        if (cachingEnabled) {
            const cachedItems = cache.getFromCache(ids);
            const cachedOfferIds = Object.keys(cachedItems);
            let newProducts = {};

            if (ids.length !== cachedOfferIds.length) {
                newProducts = await trackingRestClient.tracking.getItem({
                    offerIds: ids.filter((id) => !cachedOfferIds.includes(id)),
                });
                cache.addToCache(Object.values(newProducts));
            }

            return {
                ...cachedItems,
                ...newProducts,
            };
        } else {
            return trackingRestClient.tracking.getItem({ offerIds: ids });
        }
    }

    try {
        const items: TrackingItems = await getItems();
        return trackingItemMapper.mapFlowSpecificItems(items, flow);
    } catch (e) {
        console.warn("Error during Tracking Fetch:", e);
        throw e;
    }
};
