import { ViewItemListEvent } from "@mfe/tef-tracking-types";
import { dataLayerPush } from "#/dataLayer";
import { mapViewItemList } from "#/payloadMapper";
import { fetchCommerceData } from "#/trackingClient";
import { TrackingEventHandler } from "../../TrackingEventHandlerType";

export const handleViewItemList: TrackingEventHandler<ViewItemListEvent> = async (evt) => {
    return dataLayerPush(
        mapViewItemList(
            evt.detail.trackingEvent,
            await fetchCommerceData(evt.detail.trackingEvent.offers, evt.detail.trackingEvent.flowName),
        ),
        evt.detail.trackingEvent.mfeName,
    );
};
